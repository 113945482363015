// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cx-js": () => import("./../../../src/pages/cx.js" /* webpackChunkName: "component---src-pages-cx-js" */),
  "component---src-pages-frostbyte-js": () => import("./../../../src/pages/frostbyte.js" /* webpackChunkName: "component---src-pages-frostbyte-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-iser-js": () => import("./../../../src/pages/iser.js" /* webpackChunkName: "component---src-pages-iser-js" */),
  "component---src-pages-l-2-f-js": () => import("./../../../src/pages/l2f.js" /* webpackChunkName: "component---src-pages-l-2-f-js" */)
}

